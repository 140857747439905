@font-face {
  font-family: "Roboto";
  font-weight: 400;
  src: url("./Roboto-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 600;
  src: url("./Roboto-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Roboto";
  font-weight: 700;
  src: url("./Roboto-Bold.ttf") format("truetype");
}

#root {
  display: flex;
}